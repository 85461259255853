import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18439954"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pa-2 d-inline-block text-right text-caption w-100" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"#00ff00"}
}
const _hoisted_3 = {
  key: 1,
  style: {"color":"#00beff"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (!_ctx.isInErrorState && _ctx.currentTenantSchema)
    ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
        key: 0,
        theme: "dark",
        order: 1,
        modelValue: _ctx.navDrawerOpen,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.navDrawerOpen) = $event)),
        id: "main-nav"
      }, {
        append: _withCtx(() => [
          _createElementVNode("i", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.version) + " ", 1),
            (_ctx.user?.testAccount)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.validating)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, " -green "))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, " -blue "))
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            class: "pl-4",
            justify: "end",
            style: {"height":"65px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                md: "8",
                "align-self": "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _ctx.bensisLogo,
                    "max-width": "100",
                    alt: "Bensis Logo",
                    "aspect-ratio": "2.8"
                  }, null, 8, ["src"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "4",
                "align-self": "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    size: "small",
                    class: "mt-1",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.close()), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-menu")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_list, {
            class: "pt-0",
            density: "compact"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_divider),
              (_ctx.currentTenantSchema !== null)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 0,
                          to: '/dashboard/' + _ctx.currentTenantSchema,
                          class: "mt-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Dashboard")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema) && _ctx.currentTenant.tenantOptions.showAssetMap)
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 1,
                          to: '/asset-map/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Asset Map")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Write, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 2,
                          to: '/jobs/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Jobs")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Write, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 3,
                          to: '/reports/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Reports")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.betaTestingSchemas.includes(_ctx.currentTenantSchema) && _ctx.authorizer.authorize(_ctx.roleLevel.Write, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 4,
                          to: '/field-observations/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Field Observations")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_list_subheader, { class: "pt-4 pb-1 pl-4 nav-subheading" }, {
                      default: _withCtx(() => [
                        _createTextVNode("INFRASTRUCTURE")
                      ]),
                      _: 1
                    }),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 5,
                          to: '/wells/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Wells")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 6,
                          to: '/meters/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Meters")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 7,
                          to: '/fields/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Fields")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 8,
                          to: '/facilities/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Facilities")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 9,
                          to: '/pipelines/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Pipelines")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_list_subheader, { class: "pt-4 pb-1 pl-4 nav-subheading" }, {
                      default: _withCtx(() => [
                        _createTextVNode("PERFORMANCE")
                      ]),
                      _: 1
                    }),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 10,
                          to: '/reporting-units/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Reporting Units")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 11,
                          to: '/production/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Production")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_group, {
                          key: 12,
                          value: "Measurements"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_list_item, _mergeProps(props, {
                              color: "",
                              "append-icon": ""
                            }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Measurements")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              to: '/pressure-readings/' + _ctx.currentTenantSchema
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "ml-2 text-body-2" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Pressure Readings")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 13,
                          to: '/allocations/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Allocations")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.authorizer.authorize(_ctx.roleLevel.Read, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 14,
                          to: '/gas-analysis/' + _ctx.currentTenantSchema
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Gas Analysis")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_divider)
                  ], 64))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_subheader, { class: "pt-4 pb-1 pl-4 nav-subheading" }, {
                default: _withCtx(() => [
                  _createTextVNode("SETTINGS")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { to: "/account/" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                    default: _withCtx(() => [
                      _createTextVNode("My Account")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.authorizer.authorize(_ctx.roleLevel.TenantAdmin, _ctx.module.AssetManagement, _ctx.user, _ctx.currentTenantSchema))
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 1,
                    value: "Company Administration"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        color: "",
                        "append-icon": ""
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Company Administration")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        to: '/company-information/' + _ctx.currentTenantSchema
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "ml-2 text-body-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Company Information")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createVNode(_component_v_list_item, {
                        to: '/units-and-pressure-base/' + _ctx.currentTenantSchema
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "ml-2 text-body-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Units and Pressure Base")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createVNode(_component_v_list_item, {
                        to: '/organizations/' + _ctx.currentTenantSchema
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "ml-2 text-body-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Organizations")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createVNode(_component_v_list_item, {
                        to: '/lookup-values/' + _ctx.currentTenantSchema
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "ml-2 text-body-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Lookup Values")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorizer.authorize(_ctx.roleLevel.SystemAdmin, null, _ctx.user, null))
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 2,
                    to: "/system-administration/"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { class: "nav-list-item" }, {
                        default: _withCtx(() => [
                          _createTextVNode("System Administration")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}