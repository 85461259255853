import { toFloat } from '@/models/Utils'
import { EditCreateFields } from '../observation'
import { WellObservation, wellObservationApolloModel, WellObservationParams } from './WellObservation'

export const gasLiftPlungerApolloModel = wellObservationApolloModel(
  ` casingPressure
    tubingPressure
    sideStringPressure
    onCycle
    offCycle
    gasVolume
    hpsd`
)

export const gasLiftPlungerFields : EditCreateFields[] = [
  {
    name: 'casingPressure',
    title: 'Casing Pressure',
    type: 'decimal',
    limits: [6, 2]
  },
  {
    title: 'Tubing Pressure',
    name: 'tubingPressure',
    type: 'decimal',
    limits: [6, 2]
  },
  {
    title: 'Side String Pressure',
    name: 'sideStringPressure',
    type: 'decimal',
    limits: [6, 2],
    unit: 'Tubing Pressure',
    required: false
  },
  {
    title: 'Gas Volume',
    name: 'gasVolume',
    type: 'decimal',
    limits: [10, 4],
    unit: 'Gas',
    required: false,
    tooltip: 'Gas values are recorded at the meter’s pressure base.'
  },
  {
    title: 'High Pressure Shut Down',
    name: 'hpsd',
    type: 'decimal',
    limits: [6, 2],
    required: false
  },
  {
    title: 'On Cycle',
    name: 'onCycle',
    type: 'decimal',
    limits: [6, 2],
    required: false
  },
  {
    title: 'Off Cycle',
    name: 'offCycle',
    type: 'decimal',
    limits: [6, 2],
    required: false
  }
]

export interface GasLiftPlungerParams extends WellObservationParams {
  casingPressure?: number | null
  tubingPressure?: number | null
  sideStringPressure?: number | null
  onCycle?: number | null
  offCycle?: number | null
  gasVolume?: number | null
  hpsd?: number | null
}

export class GasLiftPlunger extends WellObservation {
  private _casingPressure: number | null = null
  private _tubingPressure: number | null = null
  private _sideStringPressure: number | null = null
  private _onCycle: number | null = null
  private _offCycle: number | null = null
  private _gasVolume: number | null = null
  private _hpsd: number | null = null

  constructor (fieldObservation?: GasLiftPlungerParams) {
    super(fieldObservation)

    if (fieldObservation !== undefined) {
      if (fieldObservation.casingPressure !== undefined) {
        this._casingPressure = fieldObservation.casingPressure
      }
      if (fieldObservation.tubingPressure !== undefined) {
        this._tubingPressure = fieldObservation.tubingPressure
      }
      if (fieldObservation.sideStringPressure !== undefined) {
        this._sideStringPressure = fieldObservation.sideStringPressure
      }
      if (fieldObservation.onCycle !== undefined) {
        this._onCycle = fieldObservation.onCycle
      }
      if (fieldObservation.offCycle !== undefined) {
        this._offCycle = fieldObservation.offCycle
      }
      if (fieldObservation.gasVolume !== undefined) {
        this._gasVolume = fieldObservation.gasVolume
      }
      if (fieldObservation.hpsd !== undefined) {
        this._hpsd = fieldObservation.hpsd
      }
    }
  }

  get casingPressure (): number | null {
    return this._casingPressure
  }

  set casingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._casingPressure = value
  }

  get tubingPressure (): number | null {
    return this._tubingPressure
  }

  set tubingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._tubingPressure = value
  }

  get sideStringPressure (): number | null {
    return this._sideStringPressure
  }

  set sideStringPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._sideStringPressure = value
  }

  get onCycle (): number | null {
    return this._onCycle
  }

  set onCycle (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._onCycle = value
  }

  get offCycle (): number | null {
    return this._offCycle
  }

  set offCycle (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._offCycle = value
  }

  get gasVolume (): number | null {
    return this._gasVolume
  }

  set gasVolume (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._gasVolume = value
  }

  get hpsd (): number | null {
    return this._hpsd
  }

  set hpsd (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._hpsd = value
  }

  getApolloFormattedModel () {
    return {
      ...super.getApolloFormattedModel(),
      casingPressure: toFloat(this.casingPressure),
      tubingPressure: toFloat(this.tubingPressure),
      sideStringPressure: toFloat(this.sideStringPressure),
      onCycle: toFloat(this.onCycle),
      offCycle: toFloat(this.offCycle),
      gasVolume: toFloat(this.gasVolume),
      hpsd: toFloat(this.hpsd)
    }
  }
}
