<template>
    <v-navigation-drawer theme="dark" v-if="!isInErrorState && currentTenantSchema" :order="1" v-model="navDrawerOpen" id="main-nav">
        <v-row class="pl-4" justify="end" style="height: 65px">
            <v-col md="8" align-self="center">
                <v-img :src="bensisLogo" max-width="100"
                       alt="Bensis Logo" aspect-ratio="2.8"></v-img>
            </v-col>
            <v-col md="4" align-self="center">
                <v-btn variant="text" size="small" class="mt-1" @click.stop="close()">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-list class="pt-0" density="compact">
            <v-divider></v-divider>
            <template v-if="currentTenantSchema !== null">
                <v-list-item :to="'/dashboard/' + currentTenantSchema"
                             class="mt-4"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Dashboard</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/asset-map/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema) && currentTenant.tenantOptions.showAssetMap">

                        <v-list-item-title class="nav-list-item">Asset Map</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/jobs/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Write, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Jobs</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/reports/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Write, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Reports</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/field-observations/' + currentTenantSchema"
                             v-if="betaTestingSchemas.includes(currentTenantSchema) && authorizer.authorize(roleLevel.Write, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Field Observations</v-list-item-title>

                </v-list-item>
                <v-list-subheader class="pt-4 pb-1 pl-4 nav-subheading">INFRASTRUCTURE</v-list-subheader>
                <v-list-item :to="'/wells/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Wells</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/meters/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Meters</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/fields/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Fields</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/facilities/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Facilities</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/pipelines/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Pipelines</v-list-item-title>

                </v-list-item>
                <v-list-subheader class="pt-4 pb-1 pl-4 nav-subheading">PERFORMANCE</v-list-subheader>
                <v-list-item :to="'/reporting-units/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Reporting Units</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/production/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Production</v-list-item-title>

                </v-list-item>
                <v-list-group
                    value="Measurements"
                    v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)"
                >
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props"  color="" append-icon="">
                            <v-list-item-title class="nav-list-item">Measurements</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list-item :to="'/pressure-readings/' + currentTenantSchema">

                            <v-list-item-title class="ml-2 text-body-2">Pressure Readings</v-list-item-title>

                    </v-list-item>
                </v-list-group>
                <v-list-item :to="'/allocations/' + currentTenantSchema"
                        v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Allocations</v-list-item-title>

                </v-list-item>
                <v-list-item :to="'/gas-analysis/' + currentTenantSchema"
                             v-if="authorizer.authorize(roleLevel.Read, module.AssetManagement, user, currentTenantSchema)">

                        <v-list-item-title class="nav-list-item">Gas Analysis</v-list-item-title>

                </v-list-item>
                <v-divider></v-divider>
            </template>
            <v-list-subheader class="pt-4 pb-1 pl-4 nav-subheading">SETTINGS</v-list-subheader>
            <v-list-item to="/account/">

                    <v-list-item-title class="nav-list-item">My Account</v-list-item-title>

            </v-list-item>
            <v-list-group
                value="Company Administration"
                v-if="authorizer.authorize(roleLevel.TenantAdmin, module.AssetManagement, user, currentTenantSchema)"
            >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" color="" append-icon="">
                        <v-list-item-title class="nav-list-item">Company Administration</v-list-item-title>
                    </v-list-item>
                </template>

                <v-list-item :to="'/company-information/' + currentTenantSchema">

                        <v-list-item-title class="ml-2 text-body-2">Company Information</v-list-item-title>

                </v-list-item>

                <v-list-item :to="'/units-and-pressure-base/' + currentTenantSchema">

                        <v-list-item-title class="ml-2 text-body-2">Units and Pressure Base</v-list-item-title>

                </v-list-item>

                <v-list-item :to="'/organizations/' + currentTenantSchema">

                        <v-list-item-title class="ml-2 text-body-2">Organizations</v-list-item-title>

                </v-list-item>

                <v-list-item :to="'/lookup-values/' + currentTenantSchema">

                        <v-list-item-title class="ml-2 text-body-2">Lookup Values</v-list-item-title>

                </v-list-item>
            </v-list-group>
            <v-list-item to="/system-administration/"
                         v-if="authorizer.authorize(roleLevel.SystemAdmin, null, user, null)">

                    <v-list-item-title class="nav-list-item">System Administration</v-list-item-title>

            </v-list-item>
        </v-list>

        <template v-slot:append>
          <i class="pa-2 d-inline-block text-right text-caption w-100">
            {{ version }}
            <template v-if="user?.testAccount">
              <span style="color:#00ff00" v-if="validating"> -green </span>
              <span style="color:#00beff" v-else> -blue </span>
            </template>
          </i>
        </template>
    </v-navigation-drawer>
</template>

<script lang="ts">
import UserAccount from '@/models/base/UserAccount'
import Authorizer, { Module, RoleLevel } from '@/models/static/Authorizer'
import { isValidating } from '@/models/static/ValidationUtils'
import Tenant from '@/models/Tenant'
import store from '@/stores'
import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component
class NavDrawer extends Vue {
  private bensisLogo = require('@/assets/images/bensis-text-logo-white.svg')
  private authorizer = Authorizer
  private roleLevel = RoleLevel
  private module = Module
  protected version = process.env.VUE_APP_UI_VERSION
  protected validating = false

  mounted () {
    if (isValidating()) {
      this.validating = true
    }
  }

  open () {
    this.navDrawerOpen = true
  }

  close () {
    this.navDrawerOpen = false
  }

  get currentTenantSchema (): string {
    return this.currentTenant?.schemaName as string
  }

  get currentTenant () {
    return store.state.currentTenant as Tenant
  }

  get betaTestingSchemas (): string[] {
    return store.getters.getBetaTestingSchemas
  }

  get user (): UserAccount {
    return store.getters.userCustomData
  }

  get navDrawerOpen (): boolean {
    return store.state.navDrawerOpen
  }

  set navDrawerOpen (navDrawerOpen: boolean) {
    store.commit('setNavDrawerOpen', navDrawerOpen)
  }

  get isInErrorState (): boolean {
    return this.$route.path === '/error'
  }
}
export default toNative(NavDrawer)
</script>

<style scoped>
    #main-nav {
        background-color: #1A212B !important;
        border-color: #1A212B !important;
    }

    .nav-subheading {
        color: #5C758F !important;
    }

    .nav-list-item {
        font-size: 15px !important;
        font-weight: 300 !important;
    }
</style>
