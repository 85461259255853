import { toFloat } from '@/models/Utils'
import { EditCreateFields } from '../observation'
import { WellObservation, wellObservationApolloModel, WellObservationParams } from './WellObservation'

export const noLiftApolloModel = wellObservationApolloModel(
  ` casingPressure
    tubingPressure`
)

export const noLiftFields : EditCreateFields[] = [
  {
    title: 'Casing Pressure',
    name: 'casingPressure',
    type: 'decimal',
    limits: [6, 2]
  },
  {
    title: 'Tubing Pressure',
    name: 'tubingPressure',
    type: 'decimal',
    limits: [6, 2]
  }
]

export interface NoLiftParams extends WellObservationParams {
  casingPressure?: number | null
  tubingPressure?: number | null
}

export class NoLift extends WellObservation {
  private _casingPressure: number | null = null
  private _tubingPressure: number | null = null
  private _waterVolume: number | null = null

  constructor (fieldObservation?: NoLiftParams) {
    super(fieldObservation)

    if (fieldObservation !== undefined) {
      if (fieldObservation.casingPressure !== undefined) {
        this._casingPressure = fieldObservation.casingPressure
      }
      if (fieldObservation.tubingPressure !== undefined) {
        this._tubingPressure = fieldObservation.tubingPressure
      }
    }
  }

  get casingPressure (): number | null {
    return this._casingPressure
  }

  set casingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._casingPressure = value
  }

  get tubingPressure (): number | null {
    return this._tubingPressure
  }

  set tubingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._tubingPressure = value
  }

  getApolloFormattedModel () {
    return {
      ...super.getApolloFormattedModel(),
      casingPressure: toFloat(this.casingPressure),
      tubingPressure: toFloat(this.tubingPressure)
    }
  }
}
