import AllocationMethod from '@/models/AllocationMethod'
import UserAccount from '@/models/base/UserAccount'
import FieldObservations from '@/models/field-observations'
import FormulaType from '@/models/FormulaType'
import Notification from '@/models/Notification'
import RootState from '@/models/RootState'
import SystemFormula from '@/models/SystemFormula'
import TenantPressureBase from '@/models/TenantPressureBase'
import TenantUnit from '@/models/TenantUnit'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import gql from 'graphql-tag'
import { createStore } from 'vuex'
import router from '../router'
import apolloProvider, { apolloClient } from '../vue-apollo'
import cacheStore from './cache-store'
import listStore from './list-store'

export default createStore({
  state: {
    user: null,
    loginLoading: false,
    currentTenant: null,
    currentTenantUnits: [] as TenantUnit[],
    currentTenantUnitsLoaded: false,
    currentTenantPressureBase: [] as TenantPressureBase[],
    allocationMethods: [] as AllocationMethod[],
    systemFormulas: [] as SystemFormula[],
    formulaTypes: [] as FormulaType[],
    redirectURL: null,
    userLoaded: false,
    navDrawerOpen: true,
    notificationDrawerOpen: false,
    fatalError: [] as string[]
  } as RootState,
  mutations: {
    setUser (state, user: CognitoUser) {
      state.user = user
    },
    setLoginLoading (state, loginLoading) {
      state.loginLoading = loginLoading
    },
    setCurrentTenant (state, currentTenant) {
      state.currentTenant = currentTenant
    },
    setCurrentTenantUnits (state, currentTenantUnits) {
      state.currentTenantUnits = currentTenantUnits
    },
    setCurrentTenantUnitsLoaded (state, currentTenantUnitsLoaded) {
      state.currentTenantUnitsLoaded = currentTenantUnitsLoaded
    },
    setCurrentTenantPressureBase (state, currentTenantPressureBase) {
      state.currentTenantPressureBase = currentTenantPressureBase
    },
    setRedirectURL (state, redirectURL) {
      state.redirectURL = redirectURL
    },
    setUserLoaded (state, userLoaded) {
      state.userLoaded = userLoaded
    },
    setNavDrawerOpen (state, navDrawerOpen) {
      state.navDrawerOpen = navDrawerOpen
    },
    setNotificationDrawerOpen (state, notificationDrawerOpen) {
      state.notificationDrawerOpen = notificationDrawerOpen
    },
    setAllocationMethods (state, allocationMethods) {
      state.allocationMethods = allocationMethods
    },
    setSystemFormulas (state, systemFormulas) {
      state.systemFormulas = systemFormulas
    },
    setFormulaTypes (state, formulaTypes) {
      state.formulaTypes = formulaTypes
    },
    setFatalError (state, fatalError) {
      state.fatalError = fatalError
    },
    addFatalError (state, fatalError) {
      state.fatalError.push(fatalError)
    }
  },
  getters: {
    userCustomData: state => {
      if (state.user) {
        const signInUserSession = state.user.getSignInUserSession()
        if (signInUserSession) {
          const idToken = signInUserSession.getIdToken()
          return {
            ...JSON.parse(idToken.payload.userTenantData as string ?? '{}'),
            ...JSON.parse(idToken.payload.appStatus as string ?? '{}')
          } as UserAccount
        }
      } return null
    },
    getLoginLoading: state => state.loginLoading,
    getUnit: state => (productName: string) => (state.currentTenantUnits.find(
      (item: TenantUnit) => item.product.name === productName))?.unit?.name,
    getPressureBase: state => Number(state.currentTenantPressureBase[0]?.pressureBase?.name),
    getPressureBaseId: state => state.currentTenantPressureBase[0]?.pressureBase?.id,
    areUnitsLoaded: state => Boolean(state.currentTenantUnitsLoaded),
    getAllocationMethods: state => state.allocationMethods,
    getSortedAllocationMethods: state => state.allocationMethods.slice().sort(
      (objA: any, objB: any) => {
        if (objA.referenceDescription.charCodeAt(2) >
                    objB.referenceDescription.charCodeAt(2)) {
          return -1
        } else if (objA.referenceDescription.charCodeAt(2) <
                    objB.referenceDescription.charCodeAt(2)) {
          return 1
        } else {
          return 0
        }
      }
    ),
    getSystemFormula: state => (formulaCode: string) => (
      state.systemFormulas.find((item: SystemFormula) => item.formulaCode === formulaCode)
    ),
    getSystemFormulaType: state => (id: string) => (
      state.systemFormulas.find((item: SystemFormula) => item.id === id)
    ),
    getDependentFormulaTypes: state => (
      state.formulaTypes.filter((formulaType: FormulaType) => !formulaType.single)
    ),
    getFormulaType: state => (id: string) => (
      state.formulaTypes.find((item: FormulaType) => item.referenceValue === id)
    ),
    getFormulaTypes: state => state.formulaTypes,
    getSystemFormulaTypes: state => state.systemFormulas,
    getFatalError: state => state.fatalError,
    getBetaTestingSchemas: () => ['riverside_testing', 'testing', 'riverside_sandbox']
  },
  actions: {
    async fetchCurrentUser (context) {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })

      context.commit('setUser', user)
      context.commit('setUserLoaded', true)

      return user
    },
    async logOut (context) {
      await Auth.signOut()
      context.commit('setUser', null)
      context.commit('setUserLoaded', false)
      await context.dispatch('updateTenant', null)
      context.commit('setRedirectURL', null)
      window.location.href = `${window.location.origin}/signin`
    },
    resetTenantStores ({ commit, state, getters }) {
      commit('WellList/resetStoreState')
      commit('WellTypeHistoryList/resetStoreState')
      commit('WellStatusHistoryList/resetStoreState')
      commit('FieldList/resetStoreState')
      commit('MeterList/resetStoreState')
      commit('MeterWellHistoryList/resetStoreState')
      commit('MeterPipelineHistoryList/resetStoreState')
      commit('MeterAliasList/resetStoreState')
      commit('MeterInterestList/resetStoreState')
      commit('WellboreList/resetStoreState')
      commit('WellboreTypeHistoryList/resetStoreState')
      commit('WellboreStatusHistoryList/resetStoreState')
      commit('ProductionList/resetStoreState')
      commit('AnalysisGasList/resetStoreState')
      commit('TenantList/resetStoreState')
      commit('ReportingUnitList/resetStoreState')
      commit('ReportingUnitAliasList/resetStoreState')
      commit('ReportingUnitDetail/resetStoreState')
      commit('ReportingUnitMeterList/resetStoreState')
      commit('ReportingUnitXrefList/resetStoreState')
      commit('ReportingUnitWellList/resetStoreState')
      commit('ReportingUnitWellboreList/resetStoreState')
      commit('ReportingUnitFacilityList/resetStoreState')
      commit('ReportingUnitPipelineList/resetStoreState')
      commit('FormulaReportingUnitList/resetStoreState')
      commit('EditCreateFormulaReportingUnitList/resetStoreState')
      commit('FormulaRuMeterList/resetStoreState')
      commit('FormulaRuRuList/resetStoreState')
      commit('FormulaBoxChipsList/resetStoreState')
      commit('FacilityList/resetStoreState')
      commit('FacilityXrefList/resetStoreState')
      commit('PipelineList/resetStoreState')
      commit('PipelineSegmentList/resetStoreState')
      commit('PipelineInterestList/resetStoreState')
      commit('PipelineAliasList/resetStoreState')
      commit('MeterDetail/resetStoreState')
      commit('WellDetail/resetStoreState')
      commit('WellAliasList/resetStoreState')
      commit('WellInterestList/resetStoreState')
      commit('WellboreDetail/resetStoreState')
      commit('FacilityDetail/resetStoreState')
      commit('FacilityInterestList/resetStoreState')
      commit('FacilityAliasList/resetStoreState')
      commit('PipelineDetail/resetStoreState')
      commit('AssetMap/resetStoreState')
      commit('CompanyAdmin/resetStoreState')
      commit('AllocationSetList/resetStoreState')
      commit('AllocationDetail/resetStoreState')
      commit('AllocationResultList/resetStoreState')
      commit('Notifications/resetStoreState')

      getters.getDependentFormulaTypes?.forEach((item: FormulaType) => {
        if (Object.keys(state).includes(`FormulaRuMeter${item.code}List`)) {
          commit(`FormulaRuMeter${item.code}List/resetStoreState`)
        }
        if (Object.keys(state).includes(`FormulaRuRu${item.code}List`)) {
          commit(`FormulaRuRu${item.code}List/resetStoreState`)
        }
        if (Object.keys(state).includes(`FormulaBoxChips${item.code}List`)) {
          commit(`FormulaBoxChips${item.code}List/resetStoreState`)
        }
      })

      getters.getAllocationMethods?.forEach((item: AllocationMethod) => {
        if (Object.keys(state).includes(`AllocationSet${item.code}List`)) {
          commit(`AllocationSet${item.code}List/resetStoreState`)
        }
      })

      Object.keys(FieldObservations.assets).forEach(key => {
        FieldObservations.assets[key].observations.forEach(item => {
          if (Object.keys(state).includes(`FieldObservation${key}${item.type}List`)) {
            commit(`FieldObservation${key}${item.type}List/resetStoreState`)
          }
        })
      })
    },
    async updateTenant (context, tenant) {
      const toErrorPage = (rejectedPromises: PromiseSettledResult<any>[]) => {
        context.commit('setFatalError', rejectedPromises.map((promise: any) => promise.reason))
        context.commit('setRedirectURL', null)
        router.push({ name: 'Unexpected Error' })
      }

      const independentOfTenantPromises = [
        context.commit('setCurrentTenant', tenant),
        context.commit('setCurrentTenantUnitsLoaded', false),
        context.dispatch('resetTenantStores')
      ]

      const rejected = (await Promise.allSettled(independentOfTenantPromises))
        .filter(promise => promise.status === 'rejected')

      if (rejected.length) {
        toErrorPage(rejected)
        throw new Error('Unexpected error')
      } else {
        try {
          await apolloProvider.defaultClient.stop()
          await apolloProvider.defaultClient.clearStore()
          if (tenant) {
            await context.dispatch('getNotifications')
            await context.dispatch('getTenantPressureBase', tenant)
            await context.dispatch('getTenantUnits', tenant)
            await context.dispatch('getFormulaTypes')
            await context.dispatch('getGlobalAllocationMethods')
            await context.dispatch('getSystemFormulas')
          }
        } catch (e) {
          toErrorPage([{
            reason: `${new Date().toUTCString()}\n\n${(e as Error).stack ?? (e as Error).message}`
          } as PromiseSettledResult<any>])
          throw new Error('Unexpected error')
        }
      }
    },
    getTenantPressureBase ({ commit }, tenant) {
      const query: any = gql`query($params: ListParameters) {
                                    tenantPressureBases (params: $params) {
                                        totalCount
                                        items {
                                            id
                                            pressureBaseId
                                            pressureBase {id, name}
                                            tenantId
                                        }
                                    }
                                }`

      const variables: object = {
        params: {
          filters: [{
            fieldType: 'Number',
            fields: ['tenant_id'],
            operator: 'equals',
            values: [String(tenant.id)]
          }]
        }
      }

      return apolloClient.query({ query, variables }).then((response) => {
        const tenantPressureBase = response.data.tenantPressureBases.items
        commit('setCurrentTenantPressureBase', tenantPressureBase)
      })
    },
    getTenantUnits ({ commit }, tenant) {
      const query: any = gql`query($params: ListParameters) {
                                    tenantUnits (params: $params) {
                                        totalCount
                                        items {
                                            id
                                            productId
                                            product {id, name}
                                            unitId
                                            unit {id, name}
                                        }
                                    }
                                }`

      const variables: object = {
        params: {
          filters: [{
            fieldType: 'Number',
            fields: ['tenant_id'],
            operator: 'equals',
            values: [String(tenant.id)]
          }]
        }
      }

      return apolloClient.query({ query, variables }).then((response) => {
        const tenantUnits = response.data.tenantUnits.items
        commit('setCurrentTenantUnits', tenantUnits)
        commit('setCurrentTenantUnitsLoaded', true)
      })
    },
    async getNotificationsCount (context) {
      const query: any = gql`query($params: ListParameters) {
                notificationsCount (params: $params) {
                    totalCount
                }
            }`

      const variables: object = {
        params: {
          filters: [{
            fieldType: 'Boolean',
            fields: ['read'],
            operator: 'equals',
            values: ['False']
          }]
        }
      }

      const response = await apolloClient.query({
        query,
        variables
      })

      const notificationsCount = response.data.notificationsCount.totalCount
      context.commit('Notifications/setNotificationsCount', notificationsCount)
    },
    async getNotifications (context) {
      context.commit('Notifications/setLoadingState', true)
      await context.dispatch('getNotificationsCount')

      if (context.rootGetters['Notifications/hasData']) {
        const query: any = gql`query($params: ListParameters) {
                    notifications (params: $params) {
                        totalCount
                        items {
                            id
                            message
                            date
                            read
                            stringifiedParameters
                        }
                    }
                }`

        const variables: object = {
          params: {
            orderBy: 'date,id',
            orderDirection: 'desc',
            filters: [{
              fieldType: 'Boolean',
              fields: ['read'],
              operator: 'equals',
              values: ['False']
            }],
            page: (context.rootState as any).Notifications.page,
            pageSize: (context.rootState as any).Notifications.pageSize
          }
        }

        const response = await apolloClient.query({
          query,
          variables,
          fetchPolicy: 'network-only'
        })

        const notifications = response.data.notifications.items
        context.commit('Notifications/setNotifications', notifications.map((notification: Notification) => ({
          ...notification,
          parameters: JSON.parse(notification.stringifiedParameters)
        })))
      }
      context.commit('Notifications/setLoadingState', false)
    },
    async getGlobalAllocationMethods (context) {
      if (context.state.allocationMethods.length === 0) {
        const query: any = gql`query($params: ListParameters) {
                                            allocationMethods (
                                                params: $params
                                            ) {
                                                totalCount
                                                items {
                                                    id
                                                    code
                                                    referenceDescription
                                                    referenceValue
                                                }
                                            }
                                        }`
        const variables: object = {
          params: {
            orderBy: 'id',
            orderDirection: 'asc'
          }
        }
        const res = await apolloClient.query({
          query,
          variables
        })

        const allocationMethods = res.data.allocationMethods.items
        context.commit('setAllocationMethods', allocationMethods)

        allocationMethods?.forEach((item: AllocationMethod) => {
          if (!Object.keys(context.state).includes(`AllocationSet${item.code}List`)) {
            this.registerModule(`AllocationSet${item.code}List`, listStore)
          }
        })
      }
    },
    async getSystemFormulas (context) {
      if (context.state.systemFormulas.length === 0) {
        const query: any = gql`query($params: ListParameters) {
                                            systemFormulas (
                                                params: $params
                                            ) {
                                                totalCount
                                                items {
                                                    id
                                                    formulaType
                                                    formulaCode
                                                    formula
                                                    relatedEntity
                                                    referenceDescription
                                                    referenceValue
                                                }
                                            }
                                        }`
        const variables: object = {
          params: {
            orderBy: 'id',
            orderDirection: 'asc'
          }
        }
        const res = await apolloClient.query({
          query,
          variables
        })

        const systemFormulas = res.data.systemFormulas.items
        context.commit('setSystemFormulas', systemFormulas)
      }
    },
    async updateFormulaTypesIfMissing (context) {
      if (!context.state.formulaTypes || context.state.formulaTypes?.length === 0) {
        await context.dispatch('getFormulaTypes')
      }
    },
    async getFormulaTypes (context) {
      const query: any = gql`query ($params: ListParameters) {
                formulaTypes (params: $params) {
                   totalCount
                   items {
                       id
                       code
                       single
                       referenceDescription
                       referenceValue
                       children {
                        id
                       }
                   }
                }
            }`

      const variables: object = {
        params: {
          orderBy: 'id',
          orderDirection: 'asc'
        }
      }
      const res = await apolloClient.query({
        query,
        variables
      })

      const formulaTypes = res.data.formulaTypes.items
      context.commit('setFormulaTypes', formulaTypes)

      formulaTypes?.forEach((item: FormulaType) => {
        if (!item.single) {
          if (!Object.keys(context.state).includes(`FormulaRuMeter${item.code}List`)) {
            this.registerModule(`FormulaRuMeter${item.code}List`, listStore)
          }
          if (!Object.keys(context.state).includes(`FormulaRuRu${item.code}List`)) {
            this.registerModule(`FormulaRuRu${item.code}List`, listStore)
          }
          if (!Object.keys(context.state).includes(`FormulaBoxChips${item.code}List`)) {
            this.registerModule(`FormulaBoxChips${item.code}List`, cacheStore)
          }
        }
      })
    }
  }
})
