import { toFloat } from '@/models/Utils'
import { EditCreateFields } from '../observation'
import { WellObservation, wellObservationApolloModel, WellObservationParams } from './WellObservation'

export const espApolloModel = wellObservationApolloModel(
  ` casingPressure
    tubingPressure
    waterVolume
    runTimePercent
    pumpOffs
    smartTime
    gasVolume
    hpsd`
)

export const espFields : EditCreateFields[] = [
  {
    title: 'Casing Pressure',
    name: 'casingPressure',
    type: 'decimal',
    limits: [6, 2]
  },
  {
    title: 'Tubing Pressure',
    name: 'tubingPressure',
    type: 'decimal',
    limits: [6, 2]
  },
  {
    title: 'Run Time Percent',
    name: 'runTimePercent',
    type: 'decimal',
    limits: [3, 2]
  },
  {
    title: 'Pump Offs',
    name: 'pumpOffs',
    type: 'decimal',
    limits: [6, 2],
    required: false
  },
  {
    title: 'Smart Time',
    name: 'smartTime',
    type: 'decimal',
    limits: [6, 2],
    required: false
  },
  {
    title: 'Water Volume',
    name: 'waterVolume',
    type: 'decimal',
    limits: [10, 4],
    unit: 'Water',
    required: false
  },
  {
    title: 'Gas Volume',
    name: 'gasVolume',
    type: 'decimal',
    limits: [10, 4],
    unit: 'Gas',
    required: false,
    tooltip: 'Gas values are recorded at the meter’s pressure base.'

  },
  {
    title: 'High Pressure Shut Down',
    name: 'hpsd',
    type: 'decimal',
    limits: [6, 2],
    required: false
  }
]

export interface ESPParams extends WellObservationParams {
  casingPressure?: number | null
  tubingPressure?: number | null
  waterVolume?: number | null
  runTimePercent?: number | null
  pumpOffs?: number | null
  smartTime?: number | null
  gasVolume?: number | null
  hpsd?: number | null
}

export class ESP extends WellObservation {
  private _casingPressure: number | null = null
  private _tubingPressure: number | null = null
  private _waterVolume: number | null = null
  private _runTimePercent: number | null = null
  private _pumpOffs: number | null = null
  private _smartTime: number | null = null
  private _gasVolume: number | null = null
  private _hpsd: number | null = null

  constructor (fieldObservation?: ESPParams) {
    super(fieldObservation)

    if (fieldObservation !== undefined) {
      if (fieldObservation.casingPressure !== undefined) {
        this._casingPressure = fieldObservation.casingPressure
      }
      if (fieldObservation.tubingPressure !== undefined) {
        this._tubingPressure = fieldObservation.tubingPressure
      }
      if (fieldObservation.waterVolume !== undefined) {
        this._waterVolume = fieldObservation.waterVolume
      }
      if (fieldObservation.runTimePercent !== undefined) {
        this._runTimePercent = fieldObservation.runTimePercent
      }
      if (fieldObservation.pumpOffs !== undefined) {
        this._pumpOffs = fieldObservation.pumpOffs
      }
      if (fieldObservation.smartTime !== undefined) {
        this._smartTime = fieldObservation.smartTime
      }
      if (fieldObservation.gasVolume !== undefined) {
        this._gasVolume = fieldObservation.gasVolume
      }
      if (fieldObservation.hpsd !== undefined) {
        this._hpsd = fieldObservation.hpsd
      }
    }
  }

  get casingPressure (): number | null {
    return this._casingPressure
  }

  set casingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._casingPressure = value
  }

  get tubingPressure (): number | null {
    return this._tubingPressure
  }

  set tubingPressure (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._tubingPressure = value
  }

  get waterVolume (): number | null {
    return this._waterVolume
  }

  set waterVolume (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._waterVolume = value
  }

  get runTimePercent (): number | null {
    return this._runTimePercent
  }

  set runTimePercent (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._runTimePercent = value
  }

  get pumpOffs (): number | null {
    return this._pumpOffs
  }

  set pumpOffs (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._pumpOffs = value
  }

  get smartTime (): number | null {
    return this._smartTime
  }

  set smartTime (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._smartTime = value
  }

  get gasVolume (): number | null {
    return this._gasVolume
  }

  set gasVolume (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._gasVolume = value
  }

  get hpsd (): number | null {
    return this._hpsd
  }

  set hpsd (value: number | null) {
    value = this.convertUndefinedToNull(value)
    this._hpsd = value
  }

  getApolloFormattedModel () {
    return {
      ...super.getApolloFormattedModel(),
      casingPressure: toFloat(this.casingPressure),
      tubingPressure: toFloat(this.tubingPressure),
      waterVolume: toFloat(this.waterVolume),
      runTimePercent: toFloat(this.runTimePercent),
      pumpOffs: toFloat(this.pumpOffs),
      smartTime: toFloat(this.smartTime),
      gasVolume: toFloat(this.gasVolume),
      hpsd: toFloat(this.hpsd)
    }
  }
}
