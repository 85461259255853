import Well from '@/models/Well'
import { AssetObservation } from '../observation'
import * as BDW from './BDW'
import * as BeamPumpProgCavity from './BeamPumpProgCavity'
import * as CO2 from './CO2'
import * as ESP from './ESP'
import * as GasLiftPlunger from './GasLiftPlunger'
import * as NoLift from './NoLift'

export default {
  ...BDW,
  ...CO2,
  ...ESP,
  ...NoLift,
  ...GasLiftPlunger,
  ...BeamPumpProgCavity
}

export const availabilityCriteria = (well: Well) => {
  const observationTypes : {title: string, value: string}[] = []

  switch (well.wellType?.referenceDescription) {
    case 'Brine Disposal Well':
      observationTypes.push({
        title: 'Brine Disposal Well', value: 'BDW'
      })
      break
    case 'CO2':
      observationTypes.push({ title: 'CO2', value: 'CO2' })
      break
  }

  switch (well.wellCurrentArtificialLiftType) {
    case 'Gas Lift':
    case 'Plunger Lift':
      observationTypes.push({
        title: 'Artificial Lift: Gas Lift, Plunger',
        value: 'GasLiftPlunger'
      })
      break
    case 'ESP':
      observationTypes.push({ title: 'Artificial Lift: ESP', value: 'ESP' })
      break
    case 'No Lift':
      observationTypes.push({ title: 'Artificial Lift: No Lift', value: 'NoLift' })
      break
    case 'Progressive Cavity':
    case (
      /^Beam Pump/gm.test(well.wellCurrentArtificialLiftType ?? '')
        ? well.wellCurrentArtificialLiftType
        : undefined
    ):
      observationTypes.push({
        title: 'Artificial Lift: Beam Pump, Progressive Cavity',
        value: 'BeamPumpProgCavity'
      })
      break
  }

  return observationTypes
}

export const wellObservations: AssetObservation[] = [
  {
    title: 'Brine Disposal Well',
    type: 'BDW',
    apolloModel: BDW.bdwApolloModel,
    editCreateFields: BDW.bdwFields,
    modelFactory: (params?: BDW.BDWParams) => new BDW.BDW(params)
  },
  {
    title: 'CO2',
    type: 'CO2',
    apolloModel: CO2.co2ApolloModel,
    editCreateFields: CO2.co2Fields,
    modelFactory: (params?: CO2.CO2Params) => new CO2.CO2(params)
  },
  {
    title: 'Artificial Lift: Gas Lift, Plunger',
    type: 'GasLiftPlunger',
    apolloModel: GasLiftPlunger.gasLiftPlungerApolloModel,
    editCreateFields: GasLiftPlunger.gasLiftPlungerFields,
    modelFactory: (params?: GasLiftPlunger.GasLiftPlungerParams) =>
      new GasLiftPlunger.GasLiftPlunger(params)
  },
  {
    title: 'Artificial Lift: ESP',
    type: 'ESP',
    apolloModel: ESP.espApolloModel,
    editCreateFields: ESP.espFields,
    modelFactory: (params?: ESP.ESPParams) => new ESP.ESP(params)
  },
  {
    title: 'Artificial Lift: Beam Pump, Progressive Cavity',
    type: 'BeamPumpProgCavity',
    apolloModel: BeamPumpProgCavity.beamPumpProgCavityApolloModel,
    editCreateFields: BeamPumpProgCavity.beamPumpProgCavityFields,
    modelFactory: (params?: BeamPumpProgCavity.BeamPumpProgCavityParams) =>
      new BeamPumpProgCavity.BeamPumpProgCavity(params)
  },
  {
    title: 'Artificial Lift: No Lift',
    type: 'NoLift',
    apolloModel: NoLift.noLiftApolloModel,
    editCreateFields: NoLift.noLiftFields,
    modelFactory: (params?: NoLift.NoLiftParams) => new NoLift.NoLift(params)
  }
]

export type WellObservations =
  BDW.BDW |
  CO2.CO2 |
  ESP.ESP |
  GasLiftPlunger.GasLiftPlunger |
  BeamPumpProgCavity.BeamPumpProgCavity |
  NoLift.NoLift

export type WellObservationsParams =
  BDW.BDWParams |
  CO2.CO2Params |
  ESP.ESPParams |
  GasLiftPlunger.GasLiftPlungerParams |
  BeamPumpProgCavity.BeamPumpProgCavityParams |
  NoLift.NoLiftParams
