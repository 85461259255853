import ID from './ID'

export const casingOptions = [
  { title: 'Open', value: true },
  { title: 'Shut-In', value: false }
]

export const leakOptions = [
  { title: 'No Leak', value: true },
  { title: 'Found Leak', value: false }
]

export interface FieldObservationParams {
  id?: ID | null,
  observationDate?: string | null,
  standardCommentIds?: ID[] | null,
  fieldComment?: string | null,
  casingStatus?: boolean,
  leakStatus?: boolean
}

export class FieldObservation {
  private _id: ID | null = null
  private _observationDate: string | null = null
  private _standardCommentIds: ID[] | null = []
  private _fieldComment: string | null = null
  private _casingStatus = true
  private _leakStatus = true

  constructor (fieldObservation?: FieldObservationParams) {
    if (fieldObservation !== undefined) {
      if (fieldObservation.id !== undefined) {
        this._id = fieldObservation.id
      }
      if (fieldObservation.observationDate !== undefined) {
        this._observationDate = fieldObservation.observationDate
      }
      if (fieldObservation.standardCommentIds !== undefined) {
        this._standardCommentIds = fieldObservation.standardCommentIds
      }
      if (fieldObservation.fieldComment !== undefined) {
        this._fieldComment = fieldObservation.fieldComment
      }
      if (fieldObservation.casingStatus !== undefined) {
        this._casingStatus = fieldObservation.casingStatus
      }
      if (fieldObservation.leakStatus !== undefined) {
        this._leakStatus = fieldObservation.leakStatus
      }
    }
  }

  get id (): ID | null {
    return this._id
  }

  set id (value: ID | null) {
    value = this.convertUndefinedToNull(value)
    this._id = value
  }

  get observationDate (): string | null {
    return this._observationDate
  }

  set observationDate (value: string | null) {
    value = this.convertUndefinedToNull(value)
    this._observationDate = value
  }

  get standardCommentIds (): ID[] | null {
    return this._standardCommentIds
  }

  set standardCommentIds (value: ID[] | null) {
    value = this.convertUndefinedToNull(value)
    this._standardCommentIds = value
  }

  get fieldComment (): string | null {
    return this._fieldComment
  }

  set fieldComment (value: string | null) {
    value = this.convertUndefinedToNull(value)
    this._fieldComment = value
  }

  get casingStatus (): boolean {
    return this._casingStatus
  }

  set casingStatus (value: boolean) {
    value = this.convertUndefinedToNull(value)
    this._casingStatus = value
  }

  get leakStatus (): boolean {
    return this._leakStatus
  }

  set leakStatus (value: boolean) {
    value = this.convertUndefinedToNull(value)
    this._leakStatus = value
  }

  convertUndefinedToNull (value: any): any {
    if (value === undefined) {
      return null
    } else {
      return value
    }
  }

  getApolloFormattedModel () {
    return {
      observationDate: this.observationDate,
      standardComments: this.standardCommentIds,
      fieldComment: this.fieldComment,
      casingStatus: this.casingStatus,
      leakStatus: this.leakStatus
    }
  }
}
