import { AssetObservation } from './observation'
import { availabilityCriteria, wellObservations } from './well'

export default {
  assets: {
    Well: {
      name: 'Well',
      propertyName: 'wellId',
      observations: wellObservations,
      cndObservations: availabilityCriteria
    }
  } as {[key: string]: {
    name: string,
    propertyName: string,
    observations: AssetObservation[],
    cndObservations: (asset: any) => {title: string, value: string}[]
  }}
}
